<template>
  <div id='game'>
    <!--    <img src='../assets/game/game-banner.png' alt='' class='banner-img'>-->
    <div class='banner_1'>
      <div class='swiper_content'>
        <!--        <div class="swiper-container">-->
        <!--        <swiper :options='swiperOptions1'>-->
        <!--          <swiper-slide v-for='(item,index) in logoList' :key='index'>-->
        <div v-if='logoList.length > 0'>
          <a :href='logoList[0].link_url && logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null'
             target='_blank'><img :src='logoList[0].img' /></a>
        </div>
        <!--          </swiper-slide>-->
        <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
        <!--                      <div class="swiper-pagination" slot="pagination"></div>-->
        <!-- 如果需要导航按钮 -->
        <!--          <div class='swiper-button-prev prev1' slot='button-prev'></div>-->
        <!--          <div class='swiper-button-next next1' slot='button-next'></div>-->
        <!--        </swiper>-->
        <!--      </div>-->
      </div>
    </div>
    <!--    游戏达人-->
    <div class='head-info-item'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{ $t('game.GameMasters') }}</span>
        </div>
        <div style='position: relative'>
          <div class='game-master-s'>
            <div class='swiper swiper1'>
              <div class='swiper-wrapper'>
                <div class='swiper-slide information-s' v-for='(item, index) in game_talent' :key='index'>
                  <a :href='item.link_url && item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'
                     style='width: 100%;'><img
                    :src='item.img' alt='' class='avatar2'></a>
                  <div class='isBackGround'></div>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
            </div>
            <div class='swiper-button-prev swiper-button-left'></div>
            <div class='swiper-button-next swiper-button-right'></div>
          </div>
        </div>
      </div>
    </div>
    <!--    优质案例（游戏商务类）-->
    <!--    <div class='head-info-item' style='background-color: #F6F6F6'>-->
    <div class='head-info-item padding-info-s'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{ $t('game.QualityCases') }}</span>
        </div>
        <div style='position: relative'>
          <div class='game-case'>
            <div class='swiper swiper3'>
              <div class='swiper-wrapper'>
                <div class='swiper-slide yanxuan-list' v-for='(item, index) in game_case' :key='index'>
                  <div class='lists-con'>
                    <img :src='item.img' alt='' class='yanxuan-avatar'>
                    <div class='know_us'>
                      <a :href='item.link_url && item.link_url.indexOf("http") >= 0  ? item.link_url : null'
                         target='_blank' style='width: 100%'>
                        <el-button class='btns'>{{ $t('game.ClickView') }}</el-button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class='swiper-button-prev swiper-button-left3'></div>
              <div class='swiper-button-next swiper-button-right3'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    近期资讯-->
    <div class='head-info-item' style='background-color: #F5F5F5;padding-bottom: 80px'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{ $t('game.RecentNews') }}</span>
        </div>
        <div style='position: relative'>
          <div class='game-information'>
            <div class='swiper swiper2'>
              <div class='swiper-wrapper'>
                <div class='swiper-slide information-s' v-for='(item, index) in information_list' :key='index'>
                  <a :href='item.link_url && item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'
                     style='width: 100%;'>
                    <img :src='item.img' alt='' class='avatar2'>
                    <div class='isBackGround'></div>
                  </a>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
            </div>
            <div class='swiper-button-prev swiper-button-left2'></div>
            <div class='swiper-button-next swiper-button-right2'></div>
          </div>
        </div>
      </div>
    </div>
    <!--    游戏主播招募-->
    <div class='recruit-item'>
      <swiper :options='swiperOptions2'>
        <swiper-slide v-for='(item,index) in game_join_img' :key='index'>
          <img :src='item' alt=''>
        </swiper-slide>
      </swiper>
      <div class='content-s'>
        <div class='form-item-s'>
          <div class='name-d'>{{ $t('game.GameRecruitment') }}</div>
          <el-form ref='form' :model='form' :rules='rules' label-position='left'>
            <div class='back-s'>
              <el-form-item label='' prop='platform'>
                <el-input v-model='form.platform' size='mini' :placeholder="$t('game.formPT')"></el-input>
              </el-form-item>
            </div>
            <div class='back-s'>
              <el-form-item label='' prop='game_name'>
                <el-input v-model='form.game_name' size='mini' :placeholder="$t('game.formGame')"></el-input>
              </el-form-item>
            </div>
            <div class='back-s'>
              <el-form-item label='' prop='fans'>
                <el-input v-model='form.fans' size='mini' :placeholder="$t('game.formFans')"></el-input>
              </el-form-item>
            </div>
            <div class='back-s'>
              <el-form-item label='' prop='is_self'>
                <el-input v-model='form.is_self' size='mini' :placeholder="$t('game.formCZ')"></el-input>
              </el-form-item>
            </div>
            <div class='back-s'>
              <el-form-item label='' prop='wechat'>
                <el-input v-model='form.wechat' size='mini' :placeholder="$t('LIVE.wechat')"></el-input>
              </el-form-item>
            </div>
            <div class='back-s'>
              <el-form-item label='' prop='tel'>
                <el-input v-model='form.tel' size='mini' :placeholder='$t("LIVE.phone")'></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width='0' style='text-align: center'>
              <el-button type='primary' size='small' @click="onSubmit('form')">{{$t("LIVE.submitText")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='data-see'>
          <h2>{{ $t('game.titleMCN') }}</h2>
          <div style='text-align: justify'>{{ $t('game.contentMCN') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import homeBanner from '../components/homeBanner'
import Swiper from 'swiper'
import { Logo, index_List } from '@/api/banner'
import { getGameBanner, gameJoin } from '@/api/game'

export default {
  name: 'game',
  components: {
    // homeBanner
  },
  data() {
    return {
      logoList: [],
      swiperOptions1: {
        loop: true,
        autoplay: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1'
        }
      },
      swiperOptions2: {
        // 自动播放
        autoplay: {
          duration: 1000
          // delay: 500
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1'
        }
      },
      swiper1: {
        loop: true, // 循环模式选项
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 2.4213,
        centeredSlides: true, // 设置活动块居中
        spaceBetween: -60, // 在slide之间设置距离（单位px）。
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0, // 旋转的角度
          stretch: 30, // 拉伸 图片间左右的间距和密集度
          depth: 60, // 深度 切换图片间上下的间距和密集度
          modifier: 0, // 修正值 该值越大前面的效果越明显
          slideShadows: false // 页面阴影效果
        },
        speed: 2000,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        initialSlide: 1,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true//修改swiper的父元素时，自动初始化swiper
      },
      // swiper-slide information-s swiper-slide-active
      swiper_info: {
        loop: false,
        loopedSlides: 2,
        loopAdditionalSlides: 0,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.swiper-button-right2',
          prevEl: '.swiper-button-prev.swiper-button-left2'
        },
        // slidesPerView: 2.81,
        notNextTick: true,
        initialSlide: 0,
        centeredSlides: true, // 设置活动块居中
        spaceBetween: -50, // 在slide之间设置距离（单位px）。
        effect: 'coverflow',
        coverflowEffect: {
          rotate: -0, // 旋转的角度
          stretch: 0, // 拉伸 图片间左右的间距和密集度
          depth: 0, // 深度 切换图片间上下的间距和密集度
          modifier: 1, // 修正值 该值越大前面的效果越明显
          slideShadows: false // 页面阴影效果
        },
        breakpoints: {
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2.01
          },
          1000: {  //当屏幕宽度大于等于776
            slidesPerView: 2.01
          },
          1200: {  //当屏幕宽度大于等于1200
            slidesPerView: 2.01
          }
        },

        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true//修改swiper的父元素时，自动初始化swiper
      },
      swiper_info2: {
        loop: true,
        loopedSlides: 2,
        loopAdditionalSlides: 0,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.swiper-button-right2',
          prevEl: '.swiper-button-prev.swiper-button-left2'
        },
        // slidesPerView: 2.81,
        notNextTick: true,
        initialSlide: 0,
        centeredSlides: true, // 设置活动块居中
        spaceBetween: -50, // 在slide之间设置距离（单位px）。
        effect: 'coverflow',
        coverflowEffect: {
          rotate: -0, // 旋转的角度
          stretch: 0, // 拉伸 图片间左右的间距和密集度
          depth: 0, // 深度 切换图片间上下的间距和密集度
          modifier: 1, // 修正值 该值越大前面的效果越明显
          slideShadows: false // 页面阴影效果
        },
        breakpoints: {
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2.81
          },
          1000: {  //当屏幕宽度大于等于776
            slidesPerView: 2.81
          },
          1200: {  //当屏幕宽度大于等于1200
            slidesPerView: 2.81
          }
        },
        on: {
          progress: function() {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i) // 指定匹配元素集缩减值
              const slideProgress = this.slides[i].progress // 当前元素集的progress值

              let modify = 0 // 偏移权重
              if (parseInt(Math.abs(slideProgress)) > 0) {
                modify = Math.abs(slideProgress) * 0.2 // 不一定要0.2，可自行调整
              }
              const translate = slideProgress * modify * 700 + 'px' // 500是swiper-slide的宽度
              const scale = 1 - Math.abs(slideProgress) / 5 // 缩放权重值，随着progress由中向两边依次递减，可自行调整
              const zIndex = 99 - Math.abs(Math.round(10 * slideProgress))
              slide.transform(`translateX(${translate}) scale(${scale})`)
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1) // 是否可见
              if (parseInt(Math.abs(slideProgress)) > 1) { // 设置了只有选中的元素以及他两遍的显示，其他隐藏
                slide.css('opacity', 0)
              }
            }
          }
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true//修改swiper的父元素时，自动初始化swiper
      },
      swiper3: {
        loop: true, // 循环模式选项
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.swiper-button-right3',
          prevEl: '.swiper-button-prev.swiper-button-left3'
        },
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: -0, // 在slide之间设置距离（单位px）。
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        initialSlide: 0,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true//修改swiper的父元素时，自动初始化swiper
      },
      form: {
        platform: '', // 目前所在短视频平台
        game_name: '', // 擅长游戏
        fans: '', // 目前粉丝
        is_self: '', // 是否能独立创作视频
        wechat: '', // 微信号
        tel: '' //联系电话
      },
      rules: {
        platform: [
          {
            required: true,
            message: this.$t('game.rulePT'),
            trigger: 'blur'
          }
        ],
        game_name: [
          {
            required: true,
            message: this.$t('game.ruleGame'),
            trigger: 'blur'
          }
        ],
        fans: [
          {
            required: true,
            message: this.$t('game.ruleFans'),
            trigger: 'blur'
          }
        ],
        is_self: [
          {
            required: true,
            message: this.$t('game.ruleCZ'),
            trigger: 'blur'
          }
        ],
        wechat: [
          {
            required: true,
            message: this.$t('LIVE.rules_wechat'),
            trigger: 'blur'
          }
        ],
        tel: [
          {
            required: true,
            message: this.$t('LIVE.rules_phone'),
            trigger: 'blur'
          }
        ]
      },
      game_talent: [], // 游戏达人
      information_list: [], // 近期资讯
      game_case: [], // 优质案例（游戏商务类）
      game_join_img: '' // 游戏主播招募背景图
    }
  },
  computed: {
    swiper2() {
      let len = this.information_list.length
      if (len == 1) {
        return this.swiper_info
      } else {
        return this.swiper_info2
      }
    }
  },
  mounted() {
    this.get_Logo()
    this.get_index_List()// 游戏主播招募背景图
    this.get_game_banner(1) // 游戏达人
    this.get_game_banner(2) // 近期资讯
    this.get_game_banner(3) // 优质案例（游戏商务类）
    let language = localStorage.getItem('locale')
  },
  methods: {
    get_Logo: function() {
      Logo(5).then(resp => {
        if (resp.status == 200) {
          this.$set(this, 'logoList', resp.data)
        }
      }).catch(error => {

      })
    },
    // 游戏主播招募背景图
    get_index_List: function() {
      index_List().then(resp => {
        if (resp.status == 200) {
          this.$set(this, 'game_join_img', resp.data.game_join_img)
        }
      }).catch(error => {
      })
    },
    get_game_banner: function(data) {
      getGameBanner({ type: data }).then(resp => {
        if (resp.status == 200) {
          if (data == 1) {
            this.$set(this, 'game_talent', resp.data)
            this.$nextTick(() => {
              let mySwiper1 = new Swiper('.swiper1', this.swiper1)
            })
          }
          if (data == 2) {
            this.$set(this, 'information_list', resp.data)
            this.$nextTick(() => {
              let mySwiper2 = new Swiper('.swiper2', this.swiper2)
            })
          }
          if (data == 3) {
            this.$set(this, 'game_case', resp.data)
            this.$nextTick(() => {
              let mySwiper3 = new Swiper('.swiper3', this.swiper3)
            })
          }
        } else {
          this.$message(resp.data.msg)
        }
      }).catch(err => {
      })
    },
    onSubmit: function(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          gameJoin(this.form).then(resp => {
            if (resp.status == 200) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
              this.form = {
                platform: '', // 目前所在短视频平台
                game_name: '', // 擅长游戏
                fans: '', // 目前粉丝
                is_self: '', // 是否能独立创作视频
                wechat: '', // 微信号
                tel: '' //联系电话
              }
            } else {
              this.$message(resp.data.msg)
            }
          }).catch(err => {
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.banner_1 {
  width: 100%;
  height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

    .swiper-container {
      width: 100%;
      height: 700px;

      .swiper-wrapper {
        width: 100%;
        height: 700px;

        .swiper-slide {
          width: 100%;
          height: 700px;

          img {
            width: 100%;
            height: 700px;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 700px;
    }
  }
}

.head-info-item {
  //display: flex;
  position: relative;
  padding: 30px 0;

  .header-anchor {
    width: 60%;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333;
    //position: relative;
    line-height: 30px;

    .images {
      width: 261px;
      position: absolute;
      left: 50%;
    }

    .popular-img {
      width: 191px;
    }

    .address-word {
      width: 181px;
    }

    .quality-img {
      width: 221px;
    }

    .game-master-s {
      background: rgba(213, 71, 36, 0.1);
      box-shadow: 0px 8px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 40px;
      height: 428px;
      width: 88%;
      overflow: hidden;
      margin: auto;
    }
  }

  .rectangle-position {
    position: absolute;
    bottom: 30px;
    left: 60px;

    img {
      width: 104px;
      height: 73px;
    }
  }

  .lefts {
    left: 140px;
    bottom: 240px;
  }

  .rectangle-right {
    position: absolute;
    top: 80px;
    right: 100px;

    img {
      width: 106px;
      height: 73px;
    }
  }

  .left-s {
    position: absolute;
    left: 0;
    top: -200px;

    img {
      width: 355px;
      height: 657px;
    }
  }

  .rights {
    right: 160px;
  }

  .swiper-slide {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }

  .avatar2 {
    width: 100%;
    height: 100%;
    //box-shadow: 0px 3px 3px 0px rgba(64, 64, 64, 0.1);
    border-radius: 30px;
  }

  .avatar2:hover {
    box-shadow: 0px 0px 7px 0px rgba(60, 18, 7, 0.1500);
  }

  .game-information {
    width: 90%;
    margin: auto;
    height: 279px;
    overflow: hidden;

    .swiper-slide-duplicate-next {
      opacity: 0 !important;
    }

    .swiper-slide-duplicate-prev {
      opacity: 0 !important;
    }

    .swiper-wrapper {
      align-items: center;
    }

    .swiper-slide-prev, .swiper-slide-next {
      width: 312px !important;
      height: 258px !important;
    }

    .swiper-slide-active {
      width: 503px !important;
      height: 279px !important;
      z-index: 33 !important;
    }
  }

  .game-case {
    width: 89.4%;
    margin: auto;
    height: 466px;
    overflow: hidden;
    //background: #D54724;
    //background: #F2F2F2;
    //box-shadow: 0px 0px 7px 0px rgba(60,18,7,0.1500);
    border-radius: 40px;
  }
}

.padding-info-s {
  padding-bottom: 120px
}

.yanxuan-list {
  width: 100%;
  height: 466px;
}

.lists-con {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-family: SourceHanSansSC;
  font-weight: 400;
  color: #FFFFFF;
  position: relative;
}

.yanxuan-avatar {
  width: 100%;
  height: 100%;
  border-radius: 0 0 0 0;
}

.imgs {
  border-radius: 0 0 0 0;
}

.yanxuan-name {
  font-size: 20px;
  line-height: 27px;
  padding: 0 26px 20px 26px;
}

.case-num-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  img {
    vertical-align: middle;
    margin-right: 5px;
  }
}

.yx-number {
  padding: 0 26px 0 26px;
  line-height: 25px;
}

.know_us {
  text-align: left;
  position: absolute;
  z-index: 999;
  bottom: 150px;
  left: 270px
}

.know_us .btns {
  background: #FFFFFF;
  color: #D54724 !important;
  padding: 8px 5px;
  font-size: 16px;
  font-family: SourceHanSansCNVF;
  font-weight: 400;
  border-radius: 4px;
  border: none;
}

.know_us .btns:hover {
  background-color: #D54724;
  border: none;
  color: #fff !important;
}

.recruit-item {
  height: 700px;
  width: 100%;
  position: relative;

  img {
    height: 700px;
    width: 100%;
  }

  .content-s {
    //width: 55%;
    margin: auto;
    //padding-top: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 120px;
    z-index: 2;
    left: 26%;
  }

  .form-item-s {
    width: 283px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(170, 169, 169, 0.44);
    border-radius: 40px;
    height: 450px;
    padding: 15px;

    .back-s {
      //background: #F19F8A;
      //border-radius: 25px;
      //padding: 0 10px 0 10px;
    }
  }

  .form-item-s .name-d {
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
  }

  .data-see {
    padding: 36px 20px 75px 20px;
    margin-left: 105px;
    //background: url("../assets/game/rounded-rectangle.png") no-repeat;
    //background-size:100% 336px;
    background: rgba(255, 255, 255, 0.7400);
    box-shadow: 2px 2px 7px 1px rgba(172, 171, 175, 0);
    opacity: 0.97;
    border-radius: 34px;
    width: 536px;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #4A4A4A;
    line-height: 24px;
    letter-spacing: 1.1px;

    h2 {
      font-size: 45px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #D54724;
      line-height: 86px;
      text-align: center;
    }
  }
}

.map-container {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  background-color: rgba(213, 71, 36, 0.1);
}

.swiper1 {
  width: 100%;
  height: 428px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px; /* 设置按钮大小 */
}

.swiper2 {
  width: 93%;
  height: 279px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px; /* 设置按钮大小 */
  margin: auto;
}

.swiper3 {
  width: 100%;
  height: 466px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px; /* 设置按钮大小 */
}
</style>
